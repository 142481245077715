import Link from "next/link";
import { LeadCreateForm } from "../forms/lead";
import { type LeadSource } from "@prisma/client";

export default function ContactUs({
  source = "CONTACT_US",
  campaign,
}: {
  source?: LeadSource;
  campaign?: string;
}) {
  return (
    <div id="contact-us" className="relative isolate p-4">
      {/* <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
        <div
          className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div> */}
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-xl font-bold tracking-tight text-foreground sm:text-4xl md:text-3xl">
          Contact Us
        </h2>
        <p className="mt-2 text-sm text-muted-foreground sm:text-lg md:leading-8">
          We&apos;re here to help and answer any question you might have. We
          look forward to hearing from you.
        </p>
      </div>
      <div className="mx-auto mt-6 max-w-xl md:mt-8">
        <LeadCreateForm source={source} campaign={campaign} />
        <p className="mt-2 text-xs text-muted-foreground md:mt-4 md:text-sm">
          By submitting this form, you agree to our{" "}
          <Link href="/terms" className="text-accented hover:underline">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link href="/privacy" className="text-accented hover:underline">
            Privacy Policy
          </Link>
          .
        </p>
      </div>
    </div>
  );
}
