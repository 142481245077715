import { Loader2 } from "lucide-react";
import React from "react";
import { Skeleton } from "./ui/skeleton";
import { cn } from "~/lib/utils";

export default function Loader({
  className,
  size = 24,
}: {
  className?: string;
  size?: number;
}) {
  return (
    <Loader2
      className={cn("animate-spin text-muted-foreground", className)}
      size={size}
    />
  );
}

export function LoaderPage() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center gap-12 px-4 py-16 ">
      <div className="flex flex-col items-center justify-center gap-2">
        <Loader />
      </div>
    </div>
  );
}

export function LoaderSkeletonPage({ className = "" }: { className?: string }) {
  return (
    <div
      className={cn(
        "flex min-h-screen items-start justify-center gap-12 px-4 py-16 ",
        className,
      )}
    >
      <div className="flex flex-col justify-start gap-2 py-12">
        {[1, 2, 3].map((_, i) => (
          <LoaderSkeleton key={i} />
        ))}
      </div>
    </div>
  );
}

export function LoaderSkeleton() {
  return (
    <div className="flex items-center space-x-4">
      <Skeleton className="h-12 w-12 rounded-full" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </div>
  );
}

export function ListLoaderSkeleton() {
  return (
    <div className="flex items-center space-x-4">
      {/* <Skeleton className="h-6 w-[100px]" /> */}
      <div className="space-y-2">
        <Skeleton className="h-4 w-[150px]" />
        <Skeleton className="h-4 w-[100px]" />
      </div>
    </div>
  );
}
